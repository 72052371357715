<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-book display-6 text-primary"></i></div>
              <div>
                  <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-5 mb-3" v-if="account_type!=='merchant'">
                <div class="row mb-2">
                    <div class="col-12 col-lg-auto">
                    <label class="col-form-label text-nowrap">Reseller</label>
                    </div>
                    <div class="col-12 col-lg">
                      <multiselect v-model="selectedReseller" :options="reseller" label="account_name" :class="{'mb-2':selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'}" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="handlePageChange(1)">
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='all'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>

                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                          {{ props.option.account_name }}
                               <span v-if="reseller.length>1">({{ reseller.length-1 }})</span>
                           
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='all'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>
                             {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                          {{ props.option.account_name }} <span v-if="reseller.length>1">({{ reseller.length-1 }})</span>
                        </span>
                      </template>
                      <span slot="noResult">Oops! No reseller found. </span>
                    </multiselect>
                    </div>
                  </div>
              </div>
              <div class="col-12 col-md-8">
                <div class="row d-none">
                  <div class="col-12 col-md-auto px-md-1 mb-3">
                    <label class="form-label mb-1">Method</label>
                    <select class="form-select pe-5" id="1" v-model="seletedMethod" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All</option>
                      <option value="auto">Auto</option>
                      <option value="manual">Manual</option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 px-md-1 mb-3">
                    <label class="form-label mb-1">Status</label>
                    <select class="form-select pe-5" id="1" v-model="selectedStatus" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All Status</option>
                      <option value="processing">Processing</option>
                      <option value="successful">Successful</option>
                      <option value="pending">Pending</option>
                      <option value="failed">Failed</option>
                      <option value="tagged_successful">Tagged Successful</option>
                    </select>
                  </div>
                  <div class="col-12 col-md-3 px-md-1 mb-3">
                    <label class="form-label mb-1">Country</label>
                    <multiselect
                      v-model="selectedCountry"
                      :options="countryList"
                      label="name"
                      class=""
                      placeholder="Select"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      :show-on-select="true"
                      :disabled="disabled"
                      @input="countryChanged"
                    >
                    <span slot="noResult">Oops! No Country found. </span>
                    </multiselect>
                  </div>
                  <div class="col-12 col-md px-md-1 mb-3">
                    <label class="form-label mb-1">Banks</label>
                    <multiselect
                      v-model="selectedBanks"
                      :options="returnBankList()"
                      label="name"
                      class=""
                      placeholder="Select"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      :show-on-select="true"
                      :disabled="disabled"
                      @input="handlePageChange(1)"
                    >
                    <template slot="singleLabel" slot-scope="props">
                      <span v-if="props.option.id!==-1">
                        <span v-if="props.option.isSuspend==1" 
                        class="text-danger me-1">&#9679;</span>
                        <span v-else class="text-success me-1">&#9679;</span>
                        
                        <span class="option__title">{{ props.option.name }}</span>
                      </span>
                    </template>

                    <template slot="option" slot-scope="props">
                      <span v-if="props.option.id!==-1">
                        <span v-if="props.option.isSuspend==1" 
                        class="text-danger me-1">&#9679;</span>
                        <span v-else class="text-success me-1">&#9679;</span>
                        <span class="option__title">{{ props.option.name }}</span>
                      </span>
                    </template>
                    <span slot="noResult">Oops! No bank found. </span>
                    </multiselect>
                  </div>
                </div>
              </div>
              
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Transaction Id</label>
                <div>
                  <input
                    type="text"
                    placeholder="Transaction Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchTransactionId"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Merchant Item Id</label>
                <div>
                  <input
                    type="text"
                    placeholder="Merchant Item Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchMerchantItemId"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3 d-none">
                <label class="form-label mb-1">Amount</label>
                <div>
                  <input
                    type="tel" @input="inputNumberOnly($event, 'searchAmount')"
                    placeholder="Amount"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchAmount"
                  />
                </div>
              </div>
              <div class="col-12 col-md-auto text-md-end mb-3 mb-lg-0  d-none">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="d-flex w-100">
                  
                  <button
                      class="btn btn-light col-auto mb-0 me-2 text-nowrap d-grid"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <span><i class="uil-redo me-1"></i> Clear</span>
                    </button>
                    <button
                      class="btn btn-info text-white me-0 text-nowrap d-grid"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <span><i class="uil-search me-1"></i> Search</span>
                    </button>
                    
                </div>

              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg-12 col-xl d-none">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                    <select class="form-select pe-5" v-model="filterType">
                      <option value="created_date">Created Date</option>
                      <option value="updated_date">Updated Date</option>
                    </select>
                  </div>
              </div>
              <div class="col-12 col-md col-xl mb-0 d-none">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>
             
              <div class="col-12 col-xl d-none">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14 position-relative">
              <!-- <div class="position-relative">
                <b-button id="tooltip-button-interactive">My tooltip is interactive</b-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div> -->
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                <thead class="text-uppercase">
                  <tr>
                    <th>#</th>
                    <th>Contract</th>
                    <th>Reseller/Upline</th>
                    <th class="d-lg-table-cell d-none">Total Payin</th>
                    <th class="d-lg-none d-table-cell text-end">Total/ Action</th>
                    <th class="d-lg-table-cell d-none">Total E-wallet</th>
                    <th class="d-lg-table-cell d-none">Total Payout</th>
                    <th class="d-lg-table-cell d-none">Total Settlement</th>
                    <th class="d-lg-table-cell d-none">Total Withdrawal</th>
                    <th class="d-lg-table-cell d-none">Total Balance</th>
                    <th class="d-lg-table-cell d-none text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td colspan="10" height="300" class="text-center text-muted">
                      <i class="uil uil-book me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="10" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  
                  <tr v-for="(value, index) in returnData" :key="index" v-show="!loading">
                    <td>
                      {{ currentPage * perPage - perPage + index + 1 }}
                    </td>
                    <td>
                      <div> 
                        <router-link :to="{name:'contract ledger reseller with id', params:{resellerid: value.reseller_db_code, id: value.contract_id }}" class="fw-medium text-dark">
                        {{ value.contract_id }}: {{ value.contract_name }}</router-link></div>
                      <div class="mb-2">
                     
                      <span v-if="value.contract_status=='unavailable'" class="text-warning me-1 text-capitalize">&#9679; {{ value.contract_status }}</span>
                      <span v-else-if="value.contract_status=='available'" class="text-success me-1 text-capitalize">&#9679;  {{ value.contract_status }}</span>
                      </div>
                      <div>
                        <span class="me-2"><i class="uil uil-arrow-circle-down font-size-15 text-success me-1"></i>
                         In {{ value.payin_rate }}% + {{ value.contract_currency }} {{convertCurrencyFormat(value.payin_extra_charges,true)}} </span>
                        <span class="d-block d-lg-inline"><i class="uil uil-arrow-circle-up font-size-15 text-danger me-1"></i>
                         Out {{ value.payout_rate }}% + {{ value.contract_currency }} {{convertCurrencyFormat(value.payout_extra_charges,true)}} </span></div>
                         <div class="mt-1"><i class="uil uil-qrcode-scan font-size-15 text-dark me-1"></i>  E-wallet {{ value.ewallet_rate }}% + {{ value.contract_currency }} {{convertCurrencyFormat(value.ewallet_extra_charges,true)}} </div>
                         <div class="mt-1"><i class="uil uil-receipt font-size-15 text-info me-1"></i> Settlement {{ value.settlement_rate }}% + {{ value.contract_currency }} {{convertCurrencyFormat(value.settlement_extra_charges,true)}} </div>
                    
                    </td>
                    <td>
                      
                      
                      <div>#{{value.reseller_id}} <div class="mb-0"> {{value.reseller_name}} </div></div>
                      <span class="badge bg-info font-size-11" v-if="value.reseller_level=='0'">Company</span>
                      <span class="badge bg-info font-size-11" v-else><span v-if="account_type=='admin'">Level {{value.reseller_level }}</span></span>
                      <div class="mt-3 font-size-13" v-if="value.reseller_parent.length"> 
                       <span class="badge bg-soft-secondary font-size-12 mb-1">Upline</span> 

                       <div v-if="currentAccount.reseller_level>value.reseller_parent[value.reseller_parent.length - 1].reseller_level">{{ value.reseller_parent[value.reseller_parent.length - 1].name }}</div>
                       <div v-else>{{ value.reseller_parent[value.reseller_parent.length - 1].name }}</div>
                      </div>
                      <div v-else></div>
                    </td>
                 
                    <td>
                      <div class="d-lg-none bg-white shadow p-3 rounded">
                        <table class="text-nowrap w-100">
                          <tr>
                            <td width="130"><i class="uil uil-arrow-circle-down font-size-15 text-success"></i> In</td>
                            <td class="text-end ps-2">   {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_payin,true) }}</td>
                          </tr>
                          <tr>
                            <td><i class="uil uil-arrow-circle-up font-size-15 text-danger"></i> Out</td>
                            <td class="text-end ps-2">   {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_payout,true) }}</td>
                          </tr>
                          <tr>
                            <td><i class="uil uil-qrcode-scan font-size-15 text-dark me-1"></i> E-wallet</td>
                            <td class="text-end ps-2">   {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_ewallet,true) }}</td>
                          </tr>
                          <tr>
                            <td><i class="uil uil-receipt font-size-15 text-info"></i> Settlement</td>
                            <td class="text-end ps-2">   {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_settlement,true) }}</td>
                          </tr>
                           <tr>
                            <td><i class="uil uil-money-withdrawal font-size-15 text-dark"></i> Withdrawal</td>
                            <td class="text-end ps-2">   {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_withdrawal,true) }}</td>
                          </tr>
                          <tr>
                            <td><i class="uil uil-wallet font-size-15 text-primary"></i> Balance</td>
                            <td class="text-end ps-2">   {{ value.contract_currency }} {{ convertCurrencyFormat(value.contract_balance,true) }}</td>
                          </tr>
                        </table>
                         <router-link :to="{name:'contract ledger with id', params: {resellerid: value.reseller_db_code, id: value.contract_id}}" v-b-tooltip.hover title="View" class="mt-2 fw-medium w-100 btn btn-outline-info px-2 py-1 font-size-14 rounded">
                        <i class="uil uil-eye font-size-15"></i> Contract Ledger
                      </router-link>
                      </div>
                      <div class="d-lg-block d-none">
                        <div><i class="uil uil-arrow-circle-down font-size-15 text-success"></i> In</div>
                        {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_payin,true) }}
                      </div>
                    </td>
                    <td class="d-lg-table-cell d-none">
                      <div><i class="uil uil-qrcode-scan font-size-15 text-dark me-1"></i> E-wallet</div>
                      {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_ewallet,true) }}
                      
                    </td>
                    <td class="d-lg-table-cell d-none">
                      <div><i class="uil uil-arrow-circle-up font-size-15 text-danger"></i> Out</div>
                      {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_payout,true) }}
                    </td>
                    <td class="d-lg-table-cell d-none">
                      <div><i class="uil uil-receipt font-size-15 text-info"></i> Settlement</div>
                      {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_settlement,true) }} 
                    </td>
                    <td class="d-lg-table-cell d-none">
                      <div><i class="uil uil-money-withdrawal font-size-15 text-dark"></i> Withdrawal</div>
                      {{ value.contract_currency }} {{ convertCurrencyFormat(value.total_withdrawal,true) }} 
                    </td>
                    <td class="d-lg-table-cell d-none">
                      <div><i class="uil uil-wallet font-size-15 text-primary"></i> Balance</div>
                      <div>{{ value.contract_currency }} {{ convertCurrencyFormat(value.contract_balance,true)  }}</div>
                    </td>
                    <td class="d-lg-table-cell d-none text-center">
                      <router-link :to="{name:'contract ledger reseller with id', params: {resellerid: value.reseller_db_code, id: value.contract_id}}" v-b-tooltip.hover title="View" class="btn btn-outline-info px-2 py-1 font-size-14 rounded">
                        <i class="uil uil-eye font-size-15"></i> 
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !loading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
/**
 * Page
 */
const PageTitle = "Reseller Contracts"
const BankDefault = {
    "id": -1,
    "name": "All Banks",
    "countryId": 0,
    "bankCode": "",
    "websiteURL": "",
    "isActive": 1,
    "category": "",
    "isSuspend": 0,
    "attachment": null,
    "tranCount": 0,
    "successTranCount": 0,
    "tranPercentage": 0,
    "createdAt": ""
}
const CountryDefault = {
      "id": -1,
      "name": "All Countries",
      "currencyId": 0,
      "currencyName": "",
      "timeZone": []
}
const MerchantDefault = 
{
  "id": "",
  "account_db_code": "all",
  "account_name": "All Merchants",
  "status": "active",
  "reseller_name": "",
  "reseller_id": "",
  "merchant_code": ""
}
const AllAccountDefault =
{
  "id": "",
  "account_username": "",
  "account_db_code": "all",
  "account_name": "All Resellers",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
const ResellerDefault =
{
  "account_db_code": "all",
  "account_name": "All Resellers",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
const CancelToken = axios.CancelToken;
let cancel;

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker,
    Multiselect
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      defaultName: appConfig.defaultAccountName,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      currencySymbol:"₹",
      selectedmerchant: MerchantDefault,
      selectedmerchantContract: MerchantDefault,
      selectedReseller: ResellerDefault,
      merchant:[MerchantDefault],
      merchantContract:[MerchantDefault],
      reseller:[ResellerDefault],
      selectedCountry:CountryDefault,
      countryList:[CountryDefault],
      selectedBanks:BankDefault,
      banksList:[BankDefault],
      seletedMethod: "",
      selectedStatus:"",
      searchTransactionId: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnTotalAmount: 0,
      returnTotalTransaction:0,
      returnSuccessRate: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      currentAccount:{
        reseller_level:0
      },
      currentMerchantInfo:{},
      create_modify_permission:false
    };
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    if (this.account_type !=='merchant'){
      if (this.$route.query.reseller==undefined && this.$route.query.merchant==undefined){
        this.$router.replace({ query: { reseller: 'all', page: this.currentPage } }).catch(() => {});
      }
      this.getReseller()
    }else{
      this.merchant = [this.currentMerchantInfo]
      this.selectedmerchant = this.merchant[0]
      this.handlePageChange(1);
    }
   
    //this.getMerchant()
    //this.getMerchantContract()
    // this.getCountries()
    // this.getBankList()
    
  }, 
  created(){
    
  },
  methods:{
    detectObject(value){
      if (typeof value === 'object'){
        return true
      }else{
        return false
      }
    },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      inputNumberOnly(event, parentModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel] = numericValue;
      },
      countryChanged(){
        this.selectedBanks=BankDefault
        this.handlePageChange(1)
      },
      returnBankList(){
        if (this.selectedCountry.id !==-1){
          return this.banksList.filter(bank => bank.countryId === this.selectedCountry.id);
        }else{
          return this.banksList
        }
      },
      getBankList(){
        console.log("getBankList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "get",
            url: '/api/bank-list.json',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            console.log(resData.result)
            if (resData.status == 200) {
              this.selectedBanks = BankDefault
              this.banksList = [this.selectedBanks]
              const isSuspend = []
              resData.result.forEach(element => {
                if (element.isSuspend === 1) {
                  isSuspend.push(element);
                } else {
                  this.banksList.push(element);
                }
              });
              isSuspend.forEach(element => {
                this.banksList.push(element);
              });
             
             
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      getCountries(){
        console.log("getCountries")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "get",
            url: '/api/countries.json',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.selectedCountry = CountryDefault,
              this.countryList = [this.selectedCountry]
              resData.result.forEach(element => {
                this.countryList.push(element);
              });
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      getReseller() {
      if(cancel !== undefined) cancel();
        console.log("getReseller")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "999999");
        bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
        bodyFormData.append("searchReseller", "")
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
          cancelToken: new CancelToken(c => cancel = c)
        }).then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.reseller = [ResellerDefault]
            this.selectedReseller = ResellerDefault
            if (this.account_type=='admin'){
              this.reseller=[AllAccountDefault]
              this.selectedReseller = this.reseller[0]
            }
            if (this.account_type=='reseller'){
              this.reseller=[resData.current_account]
              this.selectedReseller = this.reseller[0]
              this.currentAccount = resData.current_account
            }
            resData.data.forEach((element) => {
              //remove z because z don't have merchant account one
              // if (element.account_username!==this.defaultName){
              //   this.reseller.push(element);
              // }
              this.reseller.push(element);
              if (element.account_db_code==this.$route.query.reseller){
                this.selectedReseller = element
              }
            });
            if (this.$route.query.page){
                this.handlePageChange(this.$route.query.page)
                this.currentPage = this.$route.query.page
              }else{
                this.handlePageChange(1)
              }

          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            this.loading = false;
            this.disabled = false
          }
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
      },

      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.selectedCountry=CountryDefault
        this.selectedmerchant = MerchantDefault
        this.selectedmerchantContract = MerchantDefault
        this.selectedBanks=BankDefault
        this.seletedMethod= ""
        this.selectedStatus= ""
        this.searchTransactionId= ""
        this.searchMerchantItemId= ""
        this.filterType="created_date"
        this.searchAmount=""
        this.date_range = this.$refs.commonFunc.last30Days()

        this.search_value = "";
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
      console.log("getData")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      this.disable_date = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("reseller", this.selectedReseller.account_db_code);
      // bodyFormData.append("dateStart", this.date_range[0]);
      // bodyFormData.append("dateEnd", this.date_range[1]);
      console.log(this.selectedReseller.account_db_code, this.selectedmerchant.account_db_code, keyword, this.seletedMethod, this.selectedStatus, this.selectedCountry.id, this.selectedBanks.bankCode, this.searchTransactionId, this.searchMerchantItemId, this.searchAmount, this.filterType,this.date_range[0], this.date_range[1] )
      axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getContractListForReseller',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          this.returnData = [];
          var resData = response.data;
          if (resData.status == 200) {
            this.totalData = resData.total;
            var responseData = resData.data;
            this.returnData = responseData;
            
            if (this.account_type !=='merchant') {
                this.$router.replace({ query: { reseller: this.selectedReseller.account_db_code,  page: this.currentPage  } }).catch(() => {});
            }
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false
          this.disable_date = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.disabled = false
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    async exportData(){
        this.$Progress.start();
        this.disableExport = true;
        this.loadingButton = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        // bodyFormData.append("page", pages);
        // bodyFormData.append("limit", this.perPage);
        axios({
            method: "get",
            url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.export_data = [];
            var resData = response.data;
            this.totalData = resData.total
            var users = resData.users
           

            for (var i in users) {
                 // Combine date and time
                 const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
                var transaction_id = ""
                if (randomMode=="UPI"){
                  transaction_id = "320020673657"
                }else{
                  transaction_id = "IMPS/123443211240"
                }

                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode":randomMode.toString(),
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "transaction_id":transaction_id,
                  "status": randomStatus,
                };
                this.export_data.splice(i, 0, listData);
              }
              var self = this
              setTimeout(function() {  
                self.disableExport = false;
                self.loadingButton = false;
                self.exportToExcel(self.export_data,'Summary','xlsx')
              }, 1200);
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 440){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     localStorage.clear();
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disableExport = false;
            this.loadingButton = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.currentMerchantInfo = data.merchant_info;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>